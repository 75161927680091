'use client'

import { Button } from 'fukku/Button'
import { IconCloseSmall } from 'icons/components/IconCloseSmall'
import { IconInfoL } from 'icons/components/IconInfoL'
import { useEffect, useRef, useState } from 'react'
import { applyStylesIf, cx } from 'utils/cx'

import { Tooltip } from '../../../Tooltip'
import { COMM_BANNER_BG_COLOR_TYPE, type CommElement } from '../../types'
import {
	getClosedBanners,
	isValidCustomColor,
	isValidPromoColor,
	setClosedBanner,
} from '../../utils'

import styles from './ClosableWrapper.module.scss'

interface ClosableWrapperProps {
	id: string
	onShow?: (props: CommElement) => void
	isClosable: boolean
	closeButtonLabel?: string
	className?: string
	children: React.ReactNode
	isHiddenInCarousel?: boolean
	bgColorType?: COMM_BANNER_BG_COLOR_TYPE
	bgColorCode?: string
	isInverse?: boolean
	showInfoTooltip?: boolean
	infoText?: string
	infoIconLabel?: string
	infoIconButtonCarouselClassName?: string
}

interface ColorClassNamesProps {
	bgColorType?: COMM_BANNER_BG_COLOR_TYPE
	bgColorCode?: string
	isInverse?: boolean
}

const getColorClassNames = ({
	bgColorType,
	bgColorCode,
	isInverse = false,
}: ColorClassNamesProps) => {
	if (bgColorType && isValidPromoColor(bgColorType)) {
		return cx(styles[bgColorType], applyStylesIf(isInverse, styles.inverse))
	}
	if (isValidCustomColor(bgColorType, bgColorCode)) {
		return applyStylesIf(isInverse, styles.inverse)
	}
	return cx(styles.white, applyStylesIf(isInverse, styles.inverse))
}

export function ClosableWrapper({
	id,
	onShow,
	isClosable,
	closeButtonLabel,
	className,
	children,
	isHiddenInCarousel = false,
	isInverse = false,
	bgColorType,
	bgColorCode,
	showInfoTooltip,
	infoText,
	infoIconLabel,
	infoIconButtonCarouselClassName,
}: ClosableWrapperProps) {
	const commRef = useRef(null)
	const hasBeenClosed = getClosedBanners().includes(id)
	const colorStyle = isValidCustomColor(bgColorType, bgColorCode)
		? { backgroundColor: bgColorCode }
		: {}
	const colorClassNames = getColorClassNames({
		bgColorType,
		bgColorCode,
		isInverse,
	})

	const initialVisibleState = !isClosable
	const [isVisible, setIsVisible] = useState<boolean>(initialVisibleState)

	useEffect(() => {
		if (!hasBeenClosed) {
			setIsVisible(true)
		}
	}, [])

	useEffect(() => {
		if (!isHiddenInCarousel && !hasBeenClosed && onShow) {
			onShow({ id, commRef })
		}
	}, [isHiddenInCarousel, hasBeenClosed, onShow])

	const handleCloseBanner = () => {
		setClosedBanner(id)
		setIsVisible(false)
	}

	if (isVisible) {
		return (
			<div
				ref={commRef}
				className={cx(styles.closableWrapper, colorClassNames, className)}
				style={colorStyle}
			>
				{children}
				{isClosable && (
					<Button
						onClick={handleCloseBanner}
						className={styles.closeButton}
						border={false}
						buttonAttributes={{
							title: closeButtonLabel ?? '',
						}}
						inverse={isInverse}
						secondary
					>
						<IconCloseSmall width={26} height={26} />
					</Button>
				)}
				{showInfoTooltip && (
					<Tooltip
						position='bottom'
						content={<p>{infoText}</p>}
						id={`commsBannerTooltip${id}`}
						boundary={commRef}
					>
						<button
							title={infoIconLabel}
							className={cx(
								styles.infoIconButton,
								infoIconButtonCarouselClassName ??
									styles.infoIconButtonSingleComm
							)}
							tabIndex={isHiddenInCarousel ? -1 : undefined}
							aria-label='info-icon'
						>
							<IconInfoL width={12} height={12} />
						</button>
					</Tooltip>
				)}
			</div>
		)
	}

	return <></>
}
