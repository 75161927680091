import { applyStylesIf, cx } from 'utils/cx'

import styles from './TextLink.module.scss'

export enum LINK_TYPE {
	DEFAULT = 'DEFAULT',
	CONTENT = 'CONTENT',
	INSPIRATIONAL = 'INSPIRATIONAL',
}

type TextLinkStates = {
	disabled?: boolean
	unselected?: boolean
	inverse?: boolean
	active?: boolean
}

export type TextLinkClassNameProps = TextLinkStates & {
	className?: string
	type?: LINK_TYPE
	size?: 's' | 'm' | 'l'
	withIcon?: boolean
}

const getMostSpecificStyle = ({
	disabled = false,
	unselected = false,
	inverse = false,
	active = false,
}: TextLinkStates) => {
	if (disabled) {
		return styles.disabled
	}
	if (unselected && !inverse) {
		return styles.unselected
	}
	return cx(
		applyStylesIf(inverse, styles.inverse),
		applyStylesIf(active, styles.active)
	)
}

export function getTextLinkClassName({
	className,
	type = LINK_TYPE.DEFAULT,
	size = 'm',
	disabled = false,
	active = false,
	unselected = false,
	inverse = false,
	withIcon = false,
}: TextLinkClassNameProps) {
	const isDefault = type === LINK_TYPE.DEFAULT
	const isContent = type === LINK_TYPE.CONTENT
	const isInspirational = type === LINK_TYPE.INSPIRATIONAL
	const isSmall = size === 's'
	const isLarge = size === 'l'

	return cx(
		className,
		styles.link,
		applyStylesIf(withIcon, styles.withIcon),
		getMostSpecificStyle({ disabled, unselected, inverse, active }),
		applyStylesIf(isDefault, styles.default),
		applyStylesIf(isContent, styles.content),
		applyStylesIf(isInspirational, styles.inspirational),
		applyStylesIf(isSmall, styles.small),
		applyStylesIf(isLarge, styles.large)
	)
}
