import {
	getFromSessionStorage,
	setToSessionStorage,
} from 'utils/webStorage/sessionStorage'

import { COMM_BANNER_BG_COLOR_TYPE } from './types'

export const CLOSED_COMMS_BANNERS_KEY = 'closed_comms_banners'

export const getClosedBanners = () => {
	return getFromSessionStorage<Array<string>>(CLOSED_COMMS_BANNERS_KEY) ?? []
}

export const setClosedBanner = (id: string) => {
	setToSessionStorage(CLOSED_COMMS_BANNERS_KEY, [...getClosedBanners(), id])
}

export const isValidColorCode = (bgColorCode: string | undefined) => {
	const regex = /^#?([0-9A-F]{3}){1,2}$/i
	return regex.test(bgColorCode ?? '')
}

export const isValidCustomColor = (
	bgColorType: COMM_BANNER_BG_COLOR_TYPE | undefined,
	bgColorCode: string | undefined
): boolean => {
	return Boolean(
		bgColorType === COMM_BANNER_BG_COLOR_TYPE.CUSTOM &&
			isValidColorCode(bgColorCode)
	)
}

export const isValidPromoColor = (
	bgColorType: COMM_BANNER_BG_COLOR_TYPE | undefined
): boolean => {
	return Boolean(
		bgColorType &&
			Object.values(COMM_BANNER_BG_COLOR_TYPE).includes(bgColorType) &&
			bgColorType !== COMM_BANNER_BG_COLOR_TYPE.CUSTOM
	)
}

export const isValidColor = (
	bgColorType: COMM_BANNER_BG_COLOR_TYPE | undefined,
	bgColorCode: string | undefined
): boolean => {
	return Boolean(
		isValidCustomColor(bgColorType, bgColorCode) ||
			isValidPromoColor(bgColorType)
	)
}
