import { IconNuevaVentana } from 'icons/components/IconNuevaVentana'
import { ClientLink, type ClientLinkProps } from 'utils/link/client'

import { type TextLinkClassNameProps, getTextLinkClassName } from '../utils'

export type ClientTextLinkProps = ClientLinkProps & TextLinkClassNameProps

export function ClientTextLink({
	type,
	size,
	disabled,
	active,
	unselected,
	inverse,
	withIcon,
	className,
	children,
	...props
}: ClientTextLinkProps) {
	const clientTextLinkClassName = getTextLinkClassName({
		className,
		type,
		size,
		disabled,
		active,
		unselected,
		inverse,
		withIcon,
	})

	return (
		<ClientLink className={clientTextLinkClassName} {...props}>
			{children}
			{withIcon && <IconNuevaVentana width={14} height={14} />}
		</ClientLink>
	)
}
