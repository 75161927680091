'use client'

import { useEffect, useState } from 'react'
import { cx } from 'utils/cx'

import styles from './Countdown.module.scss'

const digitsLength = 2

const hInD = 24
const mInH = 60
const sInM = 60
const msInS = 1000
const defaultMaxDays = 2

function isMsInDaysRange(ms: number, days: number) {
	const nowMs = new Date().getTime()

	const twoDaysBefore = ms - days * hInD * mInH * sInM * msInS

	return nowMs <= ms && nowMs >= twoDaysBefore
}

function parseMillisecondsToDate(ms: number): ParsedDate {
	const msInM = sInM * msInS
	const msInH = mInH * msInM
	const msInD = hInD * msInH

	const restingHours = ms % msInD
	const days = (ms - restingHours) / msInD

	const restingMinutes = restingHours % msInH
	const hours = (restingHours - restingMinutes) / msInH

	const restingSeconds = restingMinutes % msInM
	const minutes = (restingMinutes - restingSeconds) / msInM

	const seconds = Math.floor(restingSeconds / msInS)

	return {
		days,
		hours,
		minutes,
		seconds,
	}
}

function padZeros(num: number, padding = digitsLength): string {
	return (Array(padding).fill('0').join('') + num.toString()).slice(
		-1 * padding
	)
}

type ParsedDate = {
	days: number
	hours: number
	minutes: number
	seconds: number
}

interface CountdownProps {
	date: string
	maxDays?: number
	className?: string
	numbersClassName?: string
}

export const Countdown = ({
	date: ISODate,
	maxDays = defaultMaxDays,
	className,
	numbersClassName,
}: CountdownProps) => {
	const dateTs = new Date(ISODate).getTime()
	const [msLeft, setMsLeft] = useState(undefined as number | undefined)

	useEffect(() => {
		if (!msLeft) {
			setMsLeft(dateTs - new Date().getTime())
		}
		const interval = setInterval(() => {
			setMsLeft((ms) => ((ms || 0) < msInS ? 0 : (ms || 0) - msInS))
		}, msInS)
		return () => {
			clearInterval(interval)
		}
	}, [])

	if (!msLeft || msLeft === 0 || !isMsInDaysRange(dateTs, maxDays)) {
		return null
	}

	const parsedDate = parseMillisecondsToDate(msLeft || 0)

	return (
		<div className={cx(styles.countdown, className)} role='timer'>
			<span className={styles.timePart}>
				<span className={numbersClassName} suppressHydrationWarning={true}>
					{padZeros(parsedDate.days)}
				</span>
				d{' '}
			</span>
			<span className={styles.timePart}>
				<span className={numbersClassName} suppressHydrationWarning={true}>
					{padZeros(parsedDate.hours)}
				</span>
				h{' '}
			</span>
			<span className={styles.timePart}>
				<span className={numbersClassName} suppressHydrationWarning={true}>
					{padZeros(parsedDate.minutes)}
				</span>
				m{' '}
			</span>
			<span className={styles.timePart}>
				<span className={numbersClassName} suppressHydrationWarning={true}>
					{padZeros(parsedDate.seconds)}
				</span>
				s
			</span>
		</div>
	)
}
