import { cx } from 'utils/cx'

import styles from './BannerTitles.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface TitlesProps {
	title?: string
	subtitle?: string
	subtitlesGroup?: {
		titleKey: string
		subtitleKey: string
	}[]
	discount?: string
}

interface BannerTitlesProps extends TitlesProps {
	isPromoBanner: boolean
}

const showTitles = ({
	title,
	subtitle,
	discount,
	subtitlesGroup,
}: TitlesProps) => {
	const hasSubtitlesGroup = subtitlesGroup && subtitlesGroup?.length > 0
	return Boolean(title || subtitle || discount || hasSubtitlesGroup)
}

export const BannerTitles = ({
	title,
	subtitlesGroup,
	subtitle,
	discount,
	isPromoBanner,
}: BannerTitlesProps) => {
	const showBannerTitles = showTitles({
		title,
		subtitle,
		discount,
		subtitlesGroup,
	})

	return showBannerTitles ? (
		<div className={styles.titles}>
			{(title || discount) && (
				<h2
					className={cx(
						styles.title,
						isPromoBanner ? text.promoTitleXS : text.uppercaseM
					)}
				>
					{title && <span>{title}</span>}
					{discount && <span>{discount}</span>}
				</h2>
			)}
			{subtitlesGroup?.map(({ titleKey, subtitleKey }) => (
				<div
					className={styles.subtitlesGroup}
					key={`${titleKey}-${subtitleKey}`}
				>
					<span className={cx(text.bodySRegular)}>{titleKey}</span>
					<span className={cx(text.bodyS)}>{subtitleKey}</span>
				</div>
			))}
			{subtitle && (
				<h3 className={cx(styles.subtitle, text.bodyS)}>{subtitle}</h3>
			)}
		</div>
	) : null
}
