export enum COMM_BANNER_TYPE {
	PROMO = 'promo',
	INFO = 'info',
}

export enum COMM_BANNER_BG_COLOR_TYPE {
	WHITE = 'white',
	BLACK = 'black',
	RED = 'red',
	LIGHT_BLUE = 'lightBlue',
	DARK_BLUE = 'darkBlue',
	LIGHT_GREEN = 'lightGreen',
	DARK_GREEN = 'darkGreen',
	ORANGE = 'orange',
	LIGHT_BURGUNDY = 'lightBurgundy',
	DARK_BURGUNDY = 'darkBurgundy',
	CUSTOM = 'custom',
}

export enum COMM_BANNER_CONTENT_COLOR_TYPE {
	WHITE = 'white',
	BLACK = 'black',
}

export interface CommElement {
	id: string
	commRef: React.RefObject<HTMLDivElement>
}
